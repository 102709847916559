/** @jsxImportSource @emotion/react */
import { Component } from "react"
import Select from "react-select"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { compose, propEq, keys, map, reject, assoc, __ } from "ramda"
import { FormControl, FormGroup, ControlLabel } from "react-bootstrap"
import { Base64 } from "js-base64"
import {
  localized,
  localizationKey,
  fetchJson,
  ninjaReportError,
  showErrorMessage,
  debounce,
  decodeBase64URL,
} from "js/includes/common/utils"
import { colors } from "js/includes/common/theme"
import MfaSwitchOption from "js/includes/components/MfaSwitchOption"
import { AlertMessage, StyledButtonLink, Text } from "@ninjaone/components"
import { getRecaptchaToken } from "js/includes/common/services/recaptcha"
import { Box } from "js/includes/components/Styled"

const StyledFormGroup = styled(FormGroup)`
  margin: 0;
`
const StyledLabel = styled(ControlLabel)`
  margin-bottom: ${props => (props.controlType && props.controlType === "select" ? "10px" : "5px")};
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: ${colors.ninjaDark};
`
const formStyle = css`
  text-align: left;
`
const selectStyle = css`
  margin: 10px 0;
`
const resendLinkStyle = css`
  display: inline-block;
  margin-top: 5px;
`

export default class MfaSwitch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: this.getOptions(props.mfaType),
      showRetryU2fButton: false,
      disableMfaSwitch: false,
    }
    this.authenticateViaU2F = this.authenticateViaU2F.bind(this)
    this.handleMfaTypeChange = this.handleMfaTypeChange.bind(this)
    this.getOptions = this.getOptions.bind(this)
  }

  getOptions = mfaType =>
    compose(reject(propEq("value", mfaType)), map(assoc("value", __, {})), keys)(this.props.mfaResponse.available_mfa)

  async authenticateViaU2F(credsRequest) {
    const credentialGetJson = JSON.parse(credsRequest)

    try {
      const publicKeyCredential = await navigator.credentials.get({
        publicKey: {
          ...credentialGetJson.publicKey,
          allowCredentials:
            credentialGetJson.publicKey.allowCredentials &&
            credentialGetJson.publicKey.allowCredentials.map(credential => ({
              ...credential,
              id: Uint8Array.from(decodeBase64URL(credential.id), c => c.charCodeAt(0)),
            })),
          challenge: Uint8Array.from(decodeBase64URL(credentialGetJson.publicKey.challenge), c => c.charCodeAt(0)),
          extensions: credentialGetJson.publicKey.extensions,
        },
      })

      if (this.props.mfaType === "U2F_TOKEN") {
        this.setState({ disableMfaSwitch: true })

        const publicKeyCredentialEncoded = {
          type: publicKeyCredential.type,
          id: publicKeyCredential.id,
          response: {
            authenticatorData: Base64.fromUint8Array(
              new Uint8Array(publicKeyCredential.response.authenticatorData),
              true,
            ),
            clientDataJSON: Base64.fromUint8Array(new Uint8Array(publicKeyCredential.response.clientDataJSON), true),
            signature: Base64.fromUint8Array(new Uint8Array(publicKeyCredential.response.signature), true),
            userHandle:
              publicKeyCredential.response.userHandle &&
              Base64.fromUint8Array(new Uint8Array(publicKeyCredential.response.userHandle), true),
          },
          clientExtensionResults: publicKeyCredential.getClientExtensionResults(),
        }

        this.props.handleSubmit({ publicKeyCredentialEncoded })
      }
    } catch (err) {
      this.setState({
        showRetryU2fButton: true,
        disableMfaSwitch: false,
      })
    }
  }

  componentDidMount() {
    const { mfaResponse } = this.props

    if (mfaResponse.mfaType === "U2F_TOKEN") {
      this.authenticateViaU2F(mfaResponse.credsRequest)
    }
  }

  async handleMfaTypeChange({ value }) {
    const { resetForm, setMfaType, mfaType, mfaResponse, onClose, setLoading } = this.props
    const previousMfaType = mfaType
    resetForm()
    setMfaType(value)
    this.setState({
      options: this.getOptions(value),
      showRetryU2fButton: false,
    })
    setLoading(true)
    try {
      const hasUser = window.application && window.application.get("user")
      const url = hasUser ? "/mfa/appuser/switch" : "/account/switch-mfa-login"
      const recaptchaToken = hasUser ? {} : { recaptchaToken: await getRecaptchaToken({ action: "switchMfaLogin" }) }
      const body = JSON.stringify({
        loginToken: mfaResponse.loginToken,
        mfaType: value,
        ...recaptchaToken,
      })

      const response = await fetchJson(url, {
        options: {
          method: "POST",
          body,
        },
        useSessionPrefix: hasUser,
      })

      if (value === "U2F_TOKEN") {
        this.authenticateViaU2F(response.credsRequest)
      }
    } catch (err) {
      if (propEq("resultCode", "INVALID_LOGIN_TOKEN", err)) {
        showErrorMessage(localized("Token expired"))
        if (onClose) {
          onClose()
        }
      } else {
        setMfaType(previousMfaType)
        this.setState({ options: this.getOptions(previousMfaType) })
        ninjaReportError("Error switching MFA Types")
      }
    } finally {
      setLoading(false)
    }
  }

  resendCode = debounce(() => {
    this.props.resetForm()
    this.handleMfaTypeChange({ value: "SMSOTP" })
  }, 500)

  render() {
    const { validateForm, mfaType, mfaCode, onChange, validation, showLabel } = this.props
    const { showRetryU2fButton, disableMfaSwitch } = this.state

    return (
      <div css={formStyle}>
        {showLabel && <label>{localized("Multi-Factor Authentication Method")}</label>}
        <Select
          components={{
            Option: MfaSwitchOption({ singleValue: false }),
            SingleValue: MfaSwitchOption({ singleValue: true }),
          }}
          onChange={this.handleMfaTypeChange}
          value={{ value: mfaType }}
          options={this.state.options}
          isSearchable={false}
          isClearable={false}
          css={selectStyle}
          isDisabled={disableMfaSwitch}
        />
        {mfaType === "U2F_TOKEN" && (
          <div>
            {localized("Insert security key and activate (if applicable)")}
            {showRetryU2fButton && (
              <>
                <div>
                  <StyledButtonLink
                    onClick={() => this.handleMfaTypeChange({ value: "U2F_TOKEN" })}
                    css={resendLinkStyle}
                  >
                    <Text token={localizationKey("Retry")} size="sm" />
                  </StyledButtonLink>
                </div>
                <Box className="m-t">
                  <AlertMessage
                    variant="warning"
                    titleToken={localizationKey("Hardware MFA authentication failed or was cancelled")}
                  >
                    {localized(
                      "The authentication process failed because either it was interrupted or there's no matching hardware MFA key registered. You can try registering the key again or using a different MFA method. If you need further assistance, contact your administrator.",
                    )}
                  </AlertMessage>
                </Box>
              </>
            )}
          </div>
        )}
        {mfaType === "TOTP" && (
          <StyledFormGroup validationState={validation.success.mfaCode === false ? "error" : null}>
            <StyledLabel>{localized("Enter time-based code")}</StyledLabel>
            <FormControl
              autoComplete="one-time-code"
              autoFocus
              name="mfaCode"
              type="text"
              value={mfaCode}
              className={`${validation.success.mfaCode === false ? "has-error" : ""}`}
              onBlur={() => validateForm(["mfaCode"])}
              {...{ onChange }}
            />
          </StyledFormGroup>
        )}
        {mfaType === "SMSOTP" && (
          <div>
            <StyledFormGroup validationState={validation.success.mfaCode === false ? "error" : null}>
              <StyledLabel>{localized("Enter verification code")}</StyledLabel>
              <FormControl
                autoComplete="off"
                autoFocus
                name="mfaCode"
                type="text"
                value={mfaCode}
                className={`${validation.success.mfaCode === false ? "has-error" : ""}`}
                onBlur={() => validateForm(["mfaCode"])}
                {...{ onChange }}
              />
            </StyledFormGroup>

            <div>
              <span className="btn-link" onClick={this.resendCode} css={resendLinkStyle}>
                {localized("Resend Code")}
              </span>
            </div>
          </div>
        )}
      </div>
    )
  }
}
